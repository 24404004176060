.customer-details-tab-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: max-content !important;

    .equipment-info {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .top {
            display: flex;
            column-gap: 2rem;

            &-right {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;

                .tags-container {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;

                    .title {
                        font-size: var(--body-text-size-sm);
                        color: var(--blue-900);
                        font-weight: 700;
                    }
                }
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
        }

        .line {
            display: flex;
            flex-direction: column;

            .title {
                font-size: var(--body-text-size-sm);
                color: var(--blue-900);
                font-weight: 700;
            }

            .value {
                width: 50%;
                display: flex;
                font-size: var(--body-text-size-md);
                color: #2c2d2e;
                width: fit-content;
                line-break: anywhere !important;
                margin-bottom: 1.25rem;
            }

            .go-to {
                font-size: var(--body-text-size-md);
                color: var(--blue-200);
                background: var(--yellow-100);
                border-radius: 8px;
                padding: 0 0.5rem;
                cursor: pointer;
                width: fit-content;
            }

            .add-button {
                font-size: var(--body-text-size-sm);
                color: #5f9074;
                text-decoration: underline;
                cursor: pointer;
                margin-bottom: 1.25rem;
            }
        }
    }
}
