.order-list-card {
    & .order-list-item,
    & .order-list-expand-item {
        &-body {
            & > .item-container {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;

                & > .section {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;

                    & > .order-number,
                    .section-title {
                        color: var(--blue-900);
                        font-weight: bold;
                    }

                    & > .total-price,
                    .total-price {
                        color: var(--grey-800);
                        font-weight: bold;
                        font-size: 1.3225rem;
                    }
                }

                & > .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;

                    & > .column {
                        & > .title {
                            color: var(--grey-800);
                        }

                        & > .content {
                            color: var(--grey-800);
                        }

                        & > .unit-price {
                            color: var(--grey-800);
                        }
                    }

                    .section-title {
                        color: var(--blue-900);
                        font-weight: bold;
                    }
                }
            }

            & > .section-spacing {
                padding-bottom: 0.833rem;
            }
        }
    }

    & .order-list-expand-item {
        padding: 0.833rem;
    }
}

.order-list-expand-item-body {
    padding: 1.25rem 0 1.5rem 0;
    border-bottom: 2px solid var(--blue-050);
    border-top: 2px solid var(--blue-050);

    .header-title {
        .title {
            color: var(--grey-800);
            font-size: var(--body-text-size-lg);
            font-weight: 700;
        }
    }

    .total-price {
        padding: 0.5rem 1.25rem;
        line-height: 1.75rem;
        display: flex;
        gap: 1.25rem;
        background-color: var(--blue-100);
        justify-content: flex-end;
        align-items: center;
        color: var(--blue-900);
        font-size: var(--body-text-size-lg);

        .value {
            font-weight: 700;
        }
    }
}

.order-list-expand-item-top {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 20px;

    .header-line {
        display: flex;
        flex-direction: column;

        .title {
            font-size: var(--body-text-size-lg);
            color: var(--blue-900);
            font-weight: 700;
            line-height: 1.5rem;
        }

        .value {
            color: var(--grey-800);
            font-size: var(--body-text-size-md);
            line-height: 1.375rem;
        }
    }

    .contact {
        display: flex;
        flex-direction: column;

        .title {
            font-size: var(--body-text-size-lg);
            color: var(--blue-900);
            font-weight: 700;
            line-height: 1.5rem;
        }

        .value {
            line-height: 1.375rem;
            font-size: var(--body-text-size-md);
        }
    }

    .address-line {
        display: flex;

        .content {
            width: 33.33%;
            margin-top: 1.25rem;

            .line {
                display: flex;
                column-gap: 6px;

                .title {
                    font-size: var(--body-text-size-lg);
                    color: var(--blue-900);
                    font-weight: 700;
                    line-height: 1.5rem;
                }

                .value {
                    font-size: var(--body-text-size-md);
                    color: var(--grey-800);
                    line-height: 1.375rem;
                }
            }

            .notes-line {
                display: flex;
                flex-direction: column;
                margin-top: 0.75rem;

                .title {
                    font-size: var(--body-text-size-lg);
                    color: var(--grey-800);
                    font-weight: 700;
                    line-height: 1.5rem;
                }

                .value {
                    font-size: var(--body-text-size-md);
                    line-height: 1.375rem;
                }
            }
        }
    }
}

.order-list-expand-item-footer {
    display: flex;
    padding: 1.5rem 0;
    line-height: 1.75rem;

    .item {
        width: 50%;
        display: flex;
        flex-direction: column;

        .title {
            display: flex;
            align-items: center;
            line-height: normal;
            column-gap: 1rem;
            font-size: var(--body-text-size-lg);
            color: var(--grey-800);
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .edit-shipping-button {
            width: 18px;
            height: 18px;
            padding: 0.3rem;

            &:hover {
                background-color: var(--blue-050);
                border-radius: 50%;
            }
        }

        .item-body {
            display: flex;
            padding: 0 0.8rem;

            .line {
                width: 50%;
                display: flex;
                flex-direction: column;

                &-title {
                    color: var(--blue-900);
                    font-size: var(--body-text-size-lg);
                    font-weight: 700;
                    line-height: 1.5rem;
                }

                .value {
                    column-gap: 0.5rem;
                    display: flex;
                    align-items: center;
                    font-size: var(--body-text-size-md);
                    line-height: 1.375rem;
                }

                .attachment {
                    background: #f5f6f7;
                    border-radius: 18px;
                    width: fit-content;
                    align-items: center;
                    padding: 0.1rem 0.5rem;
                    color: var(--grey-060);
                    cursor: pointer;
                }
            }
        }

        .notes-line {
            display: flex;
            flex-direction: column;
            margin-left: 0.8rem;
            margin-top: 0.8rem;
            line-height: 1.375rem;

            .notes-title {
                font-size: var(--body-text-size-lg);
                color: var(--grey-800);
                font-weight: 700;
            }
        }
    }
}

.order-item {
    border-bottom: 1px solid var(--blue-050);
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    //margin-top: 0.75rem;
    padding: 0.75rem 0;

    .requested-item {
        display: flex;
        flex-direction: column;
        line-height: 1.75rem;
        gap: 0.25rem;

        .header-name {
            color: var(--blue-900);
            font-weight: 700;
            font-size: var(--body-text-size-lg);
            line-height: 1.5rem;
        }

        .sub-row {
            display: flex;
            gap: 1em;
            align-items: center;
            height: 1rem;
            padding-left: 0.75rem;
            line-height: 1.375rem;

            .title {
                color: var(--grey-800);
            }

            .value {
                color: var(--grey-800);
            }

            .attachment-value {
                display: inline-block;
                margin-left: 0.5rem;
                border-radius: 0.5rem;
                padding: 0 0.5rem;
                background-color: var(--grey-100);
                color: var(--grey-800);
                cursor: pointer;
            }

            .orange-circle {
                height: 5px;
                width: 5px;
                background: var(--yellow-800);
                border-radius: 100%;
            }

            .warranty {
                padding: 0 16px;
                border-radius: 18px;
            }

            .active {
                background-color: var(--green-100);
                color: #fff;
            }

            .expired {
                background-color: var(--red-100);
                color: #fff;
            }

            .no-info {
                background-color: var(--blue-100);
                color: var(--grey-800);
            }
        }
    }

    .requested-item-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-weight: 700;
        justify-content: center;

        .unit-price {
            font-weight: 400;
            color: var(--grey-800);
        }

        .isWarranty {
            font-weight: 700;
            height: 1.2rem;
            text-align: right;

            .green {
                color: var(--green-100);
            }

            .crossed-out {
                text-decoration: line-through;
                font-weight: 400;
                color: var(--green-300);
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        &-name {
            color: var(--blue-100);
            font-weight: 700;
            font-size: var(--body-text-size-lg);
        }

        &-price-info {
            .isWarranty {
                font-weight: 700;
                height: 1.2rem;
                text-align: right;

                .green {
                    color: var(--green-100);
                }

                .crossed-out {
                    margin-right: 5px;
                    text-decoration: line-through;
                    font-weight: 400;
                }
            }

            .unit-price {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
            }
        }
    }

    .body {
        // line-height: normal;
        padding: 12px;
        padding-top: 0;

        li + li::before {
            content: ' | ';
        }

        .top-line {
            width: 90%;
            display: flex;
            flex-direction: column;

            .row-item {
                display: flex;
                flex-direction: column !important;
                gap: 0.2rem;

                .sub-row {
                    display: flex;
                    gap: 1em;
                    align-items: center;
                    height: 1rem;

                    .title {
                        color: var(--grey-800);
                    }

                    .value {
                        color: var(--grey-800);
                    }

                    .orange-circle {
                        height: 5px;
                        width: 5px;
                        background: var(--yellow-800);
                        border-radius: 100%;
                    }
                }

                .line {
                    white-space: nowrap;

                    .title {
                        font-size: var(--body-text-size-md);
                        color: var(--grey-800);
                    }

                    .value {
                        font-size: var(--body-text-size-md);
                        color: var(--grey-800);
                        margin-left: 10px;
                    }

                    .warranty {
                        padding: 0 16px;
                        border-radius: 18px;
                    }

                    .active {
                        background-color: var(--green-100);
                        color: #fff;
                    }

                    .expired {
                        background-color: var(--red-100);
                        color: #fff;
                    }

                    .no-info {
                        background-color: var(--blue-100);
                        color: var(--grey-800);
                    }

                    strong {
                        color: var(--yellow-800);
                        margin: 0 10px;
                    }
                }
            }
        }

        .notes-line {
            display: flex;
            flex-direction: column;
            width: 100%;
            word-wrap: break-word;

            .title {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
                line-height: normal;
            }

            .value {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
                line-height: normal;
            }
        }
    }

    &:last-child {
        border-bottom: none;
    }
}
