.searchPartsTableSubRowContainer {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;

    .subRowColumn {
        position: relative;
        height: 100%;

        &:not(:last-child) {
            border-right: 1px solid #f0f0f0;
        }
    }

    .partNameContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        .lineSection {
            position: relative;
            width: 40px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;

            .leftOne {
                position: relative;
                width: 50%;
                height: 100%;
            }

            .leftOneBordered {
                border-right: 1px solid #f0f0f0;
            }

            .rightOne {
                position: relative;
                width: 50%;
                height: 50%;
                border-bottom: 1px solid #f0f0f0;
            }

            .lastOne {
                border-left: 1px solid #f0f0f0;
            }
        }

        .chevronSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 40px;
            height: 100%;
            cursor: pointer;
            border-right: 1px solid #f0f0f0;

            &:hover {
                background-color: #a2adbb;
            }
        }

        .activeChevronSection {
            background-color: #a2adbb;
        }

        .disableChevronSection {
            @extend .chevronSection;
            background-color: var(--grey-050);
        }

        .partName {
            display: flex;
            flex-direction: row;
            position: relative;
            width: calc(100% - 80px);
            height: 100%;

            .refNo {
                position: relative;
                width: 71px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #f0f0f0;
                border-top: 1px solid #f0f0f0;

                span {
                    &:first-child {
                        font-size: 12.1px;
                    }

                    &:nth-child(2) {
                        font-size: 13.91px;
                    }
                }
            }

            .partNameText {
                height: 100%;
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                padding-left: 15px;
                position: relative;
                border-top: 1px solid #f0f0f0;
                font-size: 0.87rem;
                width: 100%;

                span {
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .searchedEquipmentClass {
        .partName {
            width: 100%;
        }

        .chevronSection {
            border-bottom: 1px solid #f0f0f0;
        }
    }

    .buttomBordered {
        border-bottom: 1px solid #f0f0f0;
    }

    .partNumberContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 15px;
        border-top: 1px solid #f0f0f0;
    }

    .customerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 15px;
        border-top: 1px solid #f0f0f0;

        span.companyName {
            max-width: 150px;
            font-size: 0.87rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        span.serialNumber {
            font-size: 0.75rem;
        }
    }

    .priceContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #f0f0f0;
        font-size: 0.87rem;
    }

    .actionContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #f0f0f0;
        cursor: pointer;

        &:hover {
            background-color: #a2adbb;
        }
    }

    .container-sub-item {
        font-size: 0.75rem;
        color: var(--grey-600);
        padding-left: 0.8rem;
    }

    .addToCart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: var(--yellow-800);
        border-top: 1px solid #f0f0f0;
        cursor: pointer;

        path {
            fill: var(--blue-900);
        }

        &:hover {
            background-color: var(--yellow-100);
        }

        &:active {
            box-shadow: none;

            & .button:active + & {
                border-radius: 0;
            }
        }
    }

    .disabled {
        background-color: var(--yellow-500);

        &:hover {
            background-color: var(--yellow-500);
            box-shadow: none !important;
        }
    }
}

.searchPartsDrawingContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    .lineContainer {
        flex: 1;
        position: relative;
        width: 40px;

        .verticalLine {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 20px;
            border-right: 1px solid #f0f0f0;
            height: 100%;
        }
    }

    .drawingContent {
        position: relative;
        width: calc(100% - 40px);
        height: 100%;
    }
}

.action-container > .green-tick {
    width: 100%;
    display: flex;
    justify-content: center;
}
