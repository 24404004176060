.product-cart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.8rem;
    height: 11.75rem;
    border: 1px solid var(--blue-050);
    border-radius: 8px;
    width: 16.68rem;

    .body {
        display: flex;

        .cart-img {
            position: relative;
            width: 5rem;
            min-width: 5rem;
            min-height: 5rem;
            height: 5rem;
            margin-right: 0.8rem;
            border-radius: 8px;
            overflow: hidden;
        }

        .cart-info {
            display: flex;
            flex-direction: column;

            .brand {
                font-size: var(--body-text-size-sm);
                color: var(--blue-200);
                background-color: var(--yellow-500);
                width: fit-content;
                padding: 0 0.5rem;
                border-radius: 24px;
                text-align: center;
            }

            .part-number {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
                margin-top: 0.5rem;
            }

            .part-name {
                font-size: var(--body-text-size-lg);
                color: var(--blue-900);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-info {
            font-size: var(--body-text-size-lg);
            color: black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 35%;
        }

        .button {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            padding: 0.8rem;
        }
    }
}
