.header-right {
    display: flex;
    gap: 1rem;
}

.no-image-container {
    min-height: 80px;
    width: calc(100% / 12);
}

.img-container {
    width: calc(100% / 12);

    img {
        width: 100%;
        height: 80px;
        object-fit: cover;
    }
}

div[role='dialog'] {
    .img-wrapper {
        top: 10%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.content-custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.element-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .card-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;

        .title {
            color: var(--blue-900);
            font-size: 0.889rem;
            font-weight: bold;
        }

        .column-card {
            display: flex;
            line-height: normal;
            flex-direction: column;
        }

        .desc-wrapper {
            display: flex;
            flex: 1;

            div {
                padding-right: 0.556rem;
                display: flex;
            }
        }

        .label {
            color: var(--grey-600);
            font-size: 0.778rem;
        }

        .desc {
            font-size: 0.778rem;
            padding-left: 0.356rem;
        }
    }

    .long-text {
        word-break: break-word;
        line-height: normal;
        color: var(--grey-600);
        font-size: 0.778rem;
        margin-top: 0.889rem;
    }
}

.customer-equipment-list-page-container {
    .cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25rem;
    }
}
