$containerWidth: 207px;
$containerHeight: 40px;
$space: 5px;

.activity-assignee-container {
    position: relative;
    width: fit-content;
    height: $containerHeight;
    overflow: visible;

    &.circle-assignee-name {
        width: $containerHeight;
    }

    .selected-one {
        position: relative;
        width: fit-content;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc($containerHeight / 2);
        transition: background-color 0.5s ease-in-out;
        padding: 0 0.5rem;

        &.disabled-dropdown {
            cursor: default;
        }

        &.enabled-dropdown {
            cursor: pointer;
        }

        .chevron-bottom {
            margin-left: 3px;
            pointer-events: none;

            svg {
                color: #212121;
            }
        }

        .assignee-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &.empty-assignee {
            background-color: #f5f6f7;
            border: 2px dashed #a2adbb;
            color: #a2adbb;

            svg {
                opacity: 0.5;
            }
        }

        &.enabled-dropdown:hover {
            background-color: white;
            border: 1px solid #68737f;
            font-weight: bold;

            svg {
                opacity: 1;
            }
        }

        &.focused-dropdown.enabled-dropdown {
            border: 1px solid #fdc00e;
        }
    }

    .assignee-options {
        background-color: red !important;
        position: absolute;
        width: $containerWidth;
        height: auto;
        overflow-x: visible;
        overflow-y: auto;
        z-index: 100;
        margin-top: $space;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        border-radius: 0.5rem;
        max-height: calc($containerWidth * 2);
        padding: 0.25rem 0;
        background-color: white;

        .assignee-option {
            position: relative;
            height: $containerHeight;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            background-color: white;
            white-space: nowrap;
            overflow: visible;
            padding: 0.1rem 1rem;

            .assignee-circle {
                position: relative;
                width: 30px !important;
                height: 30px !important;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: var(--body-text-size-sm);
                margin-inline: 3px;
            }

            .assignee-fullname {
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-size: var(--body-text-size-md);
            }

            &:hover {
                background-color: #f5f6f7;
                font-weight: bold;
            }

            &.active-one {
                background-color: #f5f6f7;
            }
        }
    }
}
